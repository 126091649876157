import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/project.css";
import ericssonHeader from "../../images/ericsson.png";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

function openEricsson() {
  window.open("https://www.ericsson.com/en", "_blank");
}

const ericssonPage = () => (
  <Main>
    <SEO title="Ericsson" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Ericsson" src={ericssonHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">ERICSSON</p>
          <p class="project-header-text-grey">JAVA DEVELOPER</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Employment</p>
        <p class="project-bulletpoint-text"> &#9642; Summer Intern</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-08-22</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          Summer internship at Ericsson in a total of six weeks. I worked
          together with another summer intern and the task we were assigned to
          do was to convert an application from C# to Java and to improve the
          code. To do this, we had to have an understanding of the code written
          in C# to be able to translate it. The task was independently, and we
          had to come up with our own solutions and plan our work to be able to
          deliver on time.
          <br />
          <br />
          You can checkout their{" "}
          <button
            class="project-text-link-style"
            onClick={() => openEricsson()}
          >
            webpage
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.WORK}>
          &#8592; Back to work
        </a>
      </div>
    </div>
  </Main>
);

export default ericssonPage;
